<template>
    <div>
<w-top-bar>Participante</w-top-bar>
    <div class="p-5 bg-gray-200 h-full"></div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>